import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ProductList from './components/Products/ProductList';
import ProductDetail from './components/Products/ProductDetail';
import Cart from './components/Cart/Cart';
import Checkout from './components/Cart/Checkout';
import OrderList from './components/Orders/OrderList';
import OrderDetail from './components/Orders/OrderDetail';
import CategoryList from './components/Categories/CategoryList';
import AdminDashboard from './components/Admin/AdminDashboard';
import ProductManagement from './components/Admin/ProductManagement';
import CategoryManagement from './components/Admin/CategoryManagement';
import OrderManagement from './components/Admin/OrderManagement';
import CouponManagement from './components/Admin/CouponManagement';
import ProtectedRoute from './components/Admin/ProtectedRoute';
import PrivateRoute from './utils/PrivateRoute';
import NavHeader from './utils/NavHeader';
import HomePage from './components/Home/HomePage';
import SearchProducts from './components/Products/SearchProducts';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import ViewOrderPage from './components/Orders/ViewOrderPage';
import BannerManagement from './components/Admin/BannerManagement';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: 'auth/checkTokenExpiration' });
    }, 60000); // Check every 60 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [dispatch]);

  return (
    <Router>
      <div className='sticky-top bg-white'>
        <NavHeader />
      </div>
      <Routes>
        <Route path="/" index element={<HomePage />} />
        <Route path="/login" element={
          <PrivateRoute>
            <Login />
          </PrivateRoute>
        } />
        <Route path="/register" element={<PrivateRoute><Register /></PrivateRoute>} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/products/:id" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/orders" element={<OrderList />} />
        <Route path="/view-orders" element={<ViewOrderPage />} />
        <Route path="/orders/:id" element={<OrderDetail />} />
        <Route path="/categories" element={<CategoryList />} />
        <Route path="/search" element={<SearchProducts />} />

        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/products"
          element={
            <ProtectedRoute>
              <ProductManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/categories"
          element={
            <ProtectedRoute>
              <CategoryManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/orders"
          element={
            <ProtectedRoute>
              <OrderManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/coupons"
          element={
            <ProtectedRoute>
              <CouponManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/banners"
          element={
            <ProtectedRoute>
              <BannerManagement />
            </ProtectedRoute>
          }
        />
      </Routes>

      <footer className="bg-dark text-white sticky-bottom">
        <Container className="text-center">
          <p className='m-0 py-2'>For any queries contact us: 9653380501</p>
          <p>&copy; 2024 Alsasa Store. All rights reserved.</p>
        </Container>
      </footer>
    </Router>
  );
}

export default App;
