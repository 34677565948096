import React, { useEffect, useState } from 'react';
import { Carousel, Container, Row, Col, Card, Button } from 'react-bootstrap';
import './HomePage.css';
import api from '../../api/api';
import ProductCard from '../utils/ProductCard';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../redux/slices/categorySlice';
import { Link } from 'react-router-dom';
import { latestCoupon } from '../../redux/slices/couponSlice';
import banner_1 from "./assets/banner_1.jpeg"
import banner_2 from "./assets/banner_2.jpeg"
import banner_3 from "./assets/banner_3.jpeg"
import banner_4 from "./assets/banner_4.jpeg"
import { fetchBanners } from '../../redux/slices/bannerSlice';

// import { Container, Row, Col, Card } from 'react-bootstrap';

const HomePage = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);

  const { banners } = useSelector((state) => state.banner);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(latestCoupon())
  }, [dispatch]);

  const { categories } = useSelector((state) => state.categories);
  const { coupon } = useSelector((state) => state?.coupons);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.get('/products')
        const products = response.data;
        const featured = products.filter(product => product.productType === 'Feature');
        const newArrivals = products.filter(product => product.productType === 'New Arrival');
        setFeaturedProducts(featured);
        setNewArrivals(newArrivals);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    dispatch(fetchBanners()); // Fetch banners from the server on component load
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <title>Alsasa Hijabs - Online Hijab Fashion Store</title>
        <meta name="description" content="Discover the latest hijab fashion trends at Alsasa Hijabs. Shop from a wide range of styles, colors, and fabrics designed for modern modesty." />
        <meta name="keywords" content="hijabs, hijab fashion, online hijab store, modest fashion, scarves, headscarves, Islamic clothing" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.alsasa.in/" />
        <meta property="og:title" content="Alsasa Hijabs - Online Hijab Fashion Store" />
        <meta property="og:description" content="Discover the latest hijab fashion trends at Alsasa Hijabs. Shop from a wide range of styles, colors, and fabrics." />
        <meta property="og:url" content="https://www.alsasa.in/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Alsasa Hijabs - Online Hijab Fashion Store" />
        <meta name="twitter:description" content="Explore the latest hijab trends with Alsasa Hijabs. Find the perfect style for your wardrobe." />
      </Helmet>
      <Carousel>
        {banners.map((banner, index) => (
          <Carousel.Item key={index}>
            <div
              align="center"
              style={{
                backgroundImage: `url(${banner.imageUrl})`, // Use the dynamic image URL from the server
                backgroundSize: '100% 100%',
              }}
            >
              {/* Hidden image for accessibility purposes */}
              <img
                src={banner.imageUrl}
                alt={banner.title || `Slide ${index + 1}`}
                style={{ visibility: 'hidden' }} // Keep the image hidden, using background instead
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      {/* <Carousel>
        <Carousel.Item>
          <div
            align="center"
            className="banner_img_1"
          ><img
              src={banner_1}
              alt="Slide 1"
              style={{
                visibility: 'hidden'
              }} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            align="center"
            className="banner_img_2"
          ><img
              src={banner_2}
              alt="Slide 2"
              style={{
                visibility: 'hidden'
              }} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            align="center"
            className="banner_img_3"
          ><img
              src={banner_3}
              alt="Slide 3"
              style={{
                visibility: 'hidden'
              }} />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            align="center"
            className="banner_img_4"
          ><img
              src={banner_4}
              alt="Slide 4"
              style={{
                visibility: 'hidden'
              }} />
          </div>
        </Carousel.Item>
      </Carousel> */}

      {/* {!coupon ? null :
        <div className="banner bg-warning text-white text-center py-4">
          <h2>Exclusive Offer!</h2>
          <p>Get {coupon?.discount}% off. Use code <strong>{coupon?.code}</strong></p>
        </div>
      } */}

      {/* <div className='container-fluid pt-md-5 pt-3 d-none d-lg-flex flex-wrap justify-content-around align-items-center'>
        <div className='row d-flex flex-wrap justify-content-around align-items-center'>
          {categories && categories.map((c, i) => (
            <div className='p-2' key={i}>
              <Button className='cat-button bg-transparent fw-bold w-100 border-radius-0'
                as={Link} to={`/search?categoryId=${c._id}`}>
                <div>{c.name}</div>
              </Button>
            </div>
          ))}
        </div>
      </div>

      <div className='container-fluid pt-3 d-flex d-lg-none overflow-auto'>
        <div className='row flex-nowrap'>
          {categories && categories.map((c, i) => (
            <div className='p-2' key={i}>
              <Button
                className='cat-button bg-transparent fw-bold w-100 text-wrap border-radius-0'
                as={Link}
                to={`/search?categoryId=${c._id}`}
              >
                <div className='text-center'>{c.name}</div>
              </Button>
            </div>
          ))}
        </div>
      </div> */}

      <Container className="my-4">
        <Row className="overflow-auto flex-nowrap">
          {categories.map(category => (
            <Col xs="auto" className="d-flex align-items-center px-2" key={category._id}>
              <Card className="category-card">
                <Card.Img variant="top" src={category?.image} className="category-image" />
                <Card.Body>
                  <Button
                    className='cat-button bg-transparent fw-bold w-100 text-wrap border-radius-0'
                    as={Link}
                    to={`/search?categoryId=${category._id}`}
                  >
                    <div className='text-center'>{category.name}</div>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <div className="mt-5 container-fluid p-lg-5 p-3">
        <h2 className="text-center mb-4">Featured Products</h2>
        <Row>
          {featuredProducts.map((product) => (
            <ProductCard key={product._id} product={product} />
          ))}
        </Row>
      </div>

      <div className="mt-5 container-fluid p-lg-5 p-3">
        <h2 className="text-center mb-4">New Arrivals</h2>
        <Row>
          {newArrivals.map((product) => (
            <ProductCard key={product._id} product={product} />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default HomePage;
