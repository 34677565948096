import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

const getToken = () => localStorage.getItem('token');

// Fetch Products
export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const token = getToken();
  const response = await api.get('/products', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.reverse();
});

// Create Product
export const createProduct = createAsyncThunk('products/createProduct', async (product) => {
  const token = getToken();
  const response = await api.post('/products', product, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

// Update Product
export const updateProduct = createAsyncThunk('products/updateProduct', async ({ id, ...product }) => {
  const token = getToken();
  const response = await api.put(`/products/${id}`, product, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

// Delete Product
export const deleteProduct = createAsyncThunk('products/deleteProduct', async (id) => {
  const token = getToken();
  await api.delete(`/products/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return id;
});

// Product Slice
const productSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    status: 'idle',
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.products.push(action.payload);
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const index = state.products.findIndex(product => product._id === action.payload._id);
        state.products[index] = action.payload;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.products = state.products.filter(product => product._id !== action.payload);
      });
  },
});

export default productSlice.reducer;
