import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

// Fetch banners
export const fetchBanners = createAsyncThunk('banners/fetchBanners', async () => {
  const response = await api.get('/banners');
  return response.data;
});

// Create banner
export const createBanner = createAsyncThunk('banners/createBanner', async (bannerData) => {
  const response = await api.post('/banners/add', bannerData);
  return response.data.banner;
});

// Delete banner
export const deleteBanner = createAsyncThunk('banners/deleteBanner', async (id) => {
  await api.delete(`/banners/${id}`);
  return id;
});

const bannerSlice = createSlice({
  name: 'banners',
  initialState: {
    banners: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.banners = action.payload;
      })
      .addCase(fetchBanners.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createBanner.fulfilled, (state, action) => {
        state.banners.push(action.payload);
      })
      .addCase(deleteBanner.fulfilled, (state, action) => {
        state.banners = state.banners.filter((banner) => banner._id !== action.payload);
      });
  },
});

export default bannerSlice.reducer;
