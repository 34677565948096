import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

const getToken = () => localStorage.getItem('token');

export const fetchCoupons = createAsyncThunk('coupons/fetchCoupons', async () => {
  const token = getToken();
  const response = await api.get('/coupons', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const latestCoupon = createAsyncThunk('coupons/latestCoupon', async () => {
  const response = await api.get('/coupons/latest-coupon');
  return response.data;
});

export const createCoupon = createAsyncThunk('coupons/createCoupon', async (coupon) => {
  const token = getToken();
  const response = await api.post('/coupons', coupon, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const updateCoupon = createAsyncThunk('coupons/updateCoupon', async ({ id, ...coupon }) => {
  const token = getToken();
  const response = await api.put(`/coupons/${id}`, coupon, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const deleteCoupon = createAsyncThunk('coupons/deleteCoupon', async (id) => {
  const token = getToken();
  await api.delete(`/coupons/${id}`,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return id;
});

const couponSlice = createSlice({
  name: 'coupons',
  initialState: {
    coupons: [],
    coupon: null,
    status: 'idle',
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoupons.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.coupons = action.payload;
      })
      .addCase(fetchCoupons.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(latestCoupon.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(latestCoupon.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.coupon = action.payload;
      })
      .addCase(latestCoupon.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createCoupon.fulfilled, (state, action) => {
        state.coupons.push(action.payload);
      })
      .addCase(updateCoupon.fulfilled, (state, action) => {
        const index = state.coupons.findIndex(coupon => coupon._id === action.payload._id);
        state.coupons[index] = action.payload;
      })
      .addCase(deleteCoupon.fulfilled, (state, action) => {
        state.coupons = state.coupons.filter(coupon => coupon._id !== action.payload);
      });
  },
});

export default couponSlice.reducer;
