import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const CheckoutPage = () => {
  const location = useLocation();
  const { product, selectedSize } = location.state || {};

  if (!product) {
    return <div>No product selected for checkout.</div>;
  }

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Checkout</h1>
      <Row>
        <Col lg={8} className="mb-4">
          <h3>Order Summary</h3>
          <div className="d-flex align-items-center mb-4">
            <img
              src={product.imageUrls[0]}
              alt={product.name}
              style={{ width: '150px', height: '150px', objectFit: 'cover', marginRight: '20px' }}
            />
            <div>
              <h5>{product.name}</h5>
              <p>Size: {selectedSize}</p>
              <p>Price: ${product.price.toFixed(2)}</p>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <h3>Total: ${product.price.toFixed(2)}</h3>
          <Button variant="dark" className="w-100 mb-2">
            Place Order
          </Button>
          <Button variant="outline-dark" className="w-100">
            Cancel
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CheckoutPage;
