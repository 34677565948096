import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { logout } from '../redux/slices/authSlice';
import { FaSearch } from 'react-icons/fa';
import { fetchCategories } from '../redux/slices/categorySlice';
import logo_alsasa from "../assets/logo192.png"

const NavHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);

  const [searchOpen, setSearchOpen] = useState(false); // State to toggle search input
  const { categories } = useSelector((state) => state.categories);

  const [searchQuery, setSearchQuery] = useState('')

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const toggleSearch = () => {
    if (window.innerWidth < 992) {
      document.querySelector('.navbar-toggler').click(); // Trigger the sidebar on small screens
    }
    setSearchOpen(!searchOpen);
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
      setSearchQuery('');
      setSearchOpen(false);
    }
  };

  return (
    <>
      <div className="text-center my-2">
        {/* <Link to={'/'} className='text-decoration-none text-black'> */}
          <Link to={"/"} className="d-flex justify-content-center align-items-center" style={{textDecoration: 'none', color: 'black'}}>
            <img src={logo_alsasa} alt="Al SaSa" width="50" height="50" />
              <h1>Al SaSa</h1>
          </Link>
        {/* </Link> */}
      </div>
      <Navbar key={'lg'} expand={'lg'} className="mb-3 py-3 p-2">
        <Container fluid>
          {!searchOpen && (
            <div className="d-flex align-items-center">
              <FaSearch
                className="ms-auto me-2"
                size={20}
                style={{ cursor: 'pointer' }}
                onClick={toggleSearch}
              />
            </div>
          )}
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'lg'}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${'lg'}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'lg'}`}>
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {searchOpen ? (
                <div className="d-flex justify-content-center w-100">

                  <Form className="d-flex w-100 d-lg-none d-block justify-content-center align-items-center" onSubmit={handleSearchSubmit}>
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-100"
                    />
                    <div className='px-3'>
                      <FaSearch
                        className="ms-auto me-2"
                        size={25}
                        style={{ cursor: 'pointer', }}
                        onClick={handleSearchSubmit}
                      />
                    </div>
                    <Button onClick={toggleSearch} size='sm' variant="danger" className="ms-2">Close</Button>
                  </Form>
                  <Form className="d-flex w-50 d-lg-flex d-none justify-content-center align-items-center" onSubmit={handleSearchSubmit}>
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-100"
                    />
                    <div className='px-3'>
                      <FaSearch
                        className="ms-auto me-2"
                        size={25}
                        style={{ cursor: 'pointer', }}
                        onClick={handleSearchSubmit}
                      />
                    </div>
                    <Button onClick={toggleSearch} size='sm' variant="danger" className="ms-2">Close</Button>
                  </Form>
                </div>
              ) : (
                <Nav className="justify-content-lg-center justify-content-start flex-grow-1 pe-3">
                  <Nav.Link as={Link} to="/" className=' text-black'>Home</Nav.Link>
                  <Nav.Link as={Link} to="/products" className=' text-black'>Products</Nav.Link>
                  <NavDropdown className=' text-black' title="Categories" id="categoriesDropdown">
                    {categories.map((category) => (
                      <NavDropdown.Item key={category._id} as={Link} to={`/search?categoryId=${category._id}`}>
                        {category.name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                  {isAuthenticated && !user.isAdmin ?
                    <Nav.Link as={Link} to="/view-orders" className=' text-black'>My Orders</Nav.Link>
                    : null
                  }

                  {user?.isAdmin && (
                    <NavDropdown title="Admin" id={`offcanvasNavbarDropdown-expand-${'lg'}`}>
                      <NavDropdown.Item as={Link} to="/admin/products" className=' text-black'>Manage Products</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/admin/categories" className=' text-black'>Manage Categories</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/admin/coupons" className=' text-black'>Manage Coupons</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/admin/orders" className=' text-black'>Manage Orders</NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/admin/banners" className=' text-black'>Manage Banners</NavDropdown.Item>
                    </NavDropdown>
                  )}
                </Nav>
              )}
              <div className='d-lg-none d-block'>
                {isAuthenticated ? (
                  <div className='d-flex flex-wrap justify-content-center align-items-center'>
                    <Nav.Link as={Link} to="/cart">
                      Cart ({cartItems.length})
                    </Nav.Link>
                    <Button className='mx-2' size='sm' variant="danger" onClick={handleLogout}>Logout</Button>
                  </div>
                ) : (
                  <Nav.Link as={Link} to="/login">Login</Nav.Link>
                )}
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <div className='d-lg-block d-none'>
            {isAuthenticated ? (
              <div className='d-flex flex-wrap justify-content-center align-items-center'>
                <Nav.Link as={Link} to="/cart">
                  Cart ({cartItems.length})
                </Nav.Link>
                <Button className='mx-2' size='sm' variant="danger" onClick={handleLogout}>Logout</Button>
              </div>
            ) : (
              <Nav.Link as={Link} to="/login">Login</Nav.Link>
            )}
          </div>
        </Container>
      </Navbar>
      <div className='bg-light text-center py-1'>
        Free Shipping Over Rs. 1999/-
      </div>
    </>
  );
};

export default NavHeader;
