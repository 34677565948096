import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async () => {
  const response = await api.get('/orders');
  return response.data.reverse();
});

export const placeOrder = createAsyncThunk('orders/placeOrder', async (order) => {
  const response = await api.post('/orders', order);
  return response.data;
});

export const updateOrderStatus = createAsyncThunk('orders/updateOrderStatus', async ({ id, status }) => {
  const response = await api.put(`/orders/${id}`, { status });
  return response.data;
});

export const fetchOrdersByUserId = createAsyncThunk('orders/fetchOrdersByUserId', async (userId) => {
  const response = await api.get(`/orders/user/${userId}`);
  return response.data;
});

const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    orders: [],
    status: 'idle',
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.orders.push(action.payload);
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        const index = state.orders.findIndex(order => order._id === action.payload._id);
        state.orders[index] = action.payload;
      })
      .addCase(fetchOrdersByUserId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrdersByUserId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = action.payload;
      })
      .addCase(fetchOrdersByUserId.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

export default orderSlice.reducer;
