import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, deleteCategory, createCategory, updateCategory } from '../../redux/slices/categorySlice';

const CategoryManagement = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);
  const [newCategory, setNewCategory] = useState({ name: '', description: '', image: '' });
  const [editingCategory, setEditingCategory] = useState(null); // State to track which category is being edited

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteCategory(id));
  };

  const handleCreateOrUpdate = () => {
    if (editingCategory) {
      // If editingCategory is set, update the category
      dispatch(updateCategory({ id: editingCategory._id, ...newCategory }));
      setEditingCategory(null); // Reset after updating
    } else {
      // If no editingCategory, create a new one
      dispatch(createCategory(newCategory));
    }
    setNewCategory({ name: '', description: '', image: '' }); // Clear the input fields after creation or update
  };

  const handleEdit = (category) => {
    setEditingCategory(category);
    setNewCategory({ name: category?.name, description: category?.description, image: category?.image });
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Manage Categories</h2>
      <div className="card p-4 mb-4">
        <div className="form-group mb-3">
          <label htmlFor="categoryName">Category Name</label>
          <input
            type="text"
            id="categoryName"
            className="form-control"
            placeholder="Enter category name"
            value={newCategory.name}
            onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="categoryDescription">Category Description</label>
          <textarea
            id="categoryDescription"
            className="form-control"
            placeholder="Enter category description"
            value={newCategory.description}
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="categoryImage">Category Image URL</label>
          <input
            type="text"
            id="categoryImage"
            className="form-control"
            placeholder="Enter category image URL"
            value={newCategory.image}
            onChange={(e) => setNewCategory({ ...newCategory, image: e.target.value })}
          />
        </div>
        <button className="btn btn-primary" onClick={handleCreateOrUpdate}>
          {editingCategory ? 'Update Category' : 'Create Category'}
        </button>
        {editingCategory && (
          <button
            className="btn btn-secondary ms-2"
            onClick={() => {
              setEditingCategory(null);
              setNewCategory({ name: '', description: '', image: '' });
            }}
          >
            Cancel
          </button>
        )}
      </div>

      <ul className="list-group">
        {categories.map((category) => (
          <li key={category?._id} className="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <h5>{category?.name}</h5>
              <p>{category?.description}</p>
              {category?.image && <img src={category?.image} alt={category?.name} style={{ width: '100px', height: 'auto', borderRadius: '8px' }} />}
            </div>
            <div>
              <button
                className="btn btn-outline-secondary me-2"
                onClick={() => handleEdit(category)}
              >
                Edit
              </button>
              <button
                className="btn btn-outline-danger"
                onClick={() => handleDelete(category?._id)}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryManagement;
