import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCoupons, deleteCoupon, createCoupon, updateCoupon } from '../../redux/slices/couponSlice';

const CouponManagement = () => {
  const dispatch = useDispatch();
  const { coupons } = useSelector((state) => state.coupons);
  const [newCoupon, setNewCoupon] = useState({ code: '', discount: 0 });

  useEffect(() => {
    dispatch(fetchCoupons());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteCoupon(id));
  };

  const handleCreate = () => {
    dispatch(createCoupon(newCoupon));
    setNewCoupon({ code: '', discount: 0 });
  };

  const handleUpdate = (id, updatedCoupon) => {
    dispatch(updateCoupon({ id, ...updatedCoupon }));
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Manage Coupons</h2>
      <div className="card p-4 mb-4">
        <div className="form-group mb-3">
          <label htmlFor="code">Coupon Code</label>
          <input
            type="text"
            id="code"
            className="form-control"
            placeholder="Enter coupon code"
            value={newCoupon.code}
            onChange={(e) => setNewCoupon({ ...newCoupon, code: e.target.value })}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="discount">Discount (%)</label>
          <input
            type="number"
            id="discount"
            className="form-control"
            placeholder="Enter discount percentage"
            value={newCoupon.discount}
            onChange={(e) => setNewCoupon({ ...newCoupon, discount: Number(e.target.value) })}
          />
        </div>
        <button className="btn btn-primary" onClick={handleCreate}>Create Coupon</button>
      </div>

      <ul className="list-group">
        {coupons.map((coupon) => (
          <li key={coupon._id} className="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <h5>{coupon.code}</h5>
              <p>{coupon.discount}% Discount</p>
            </div>
            <div>
              <button
                className="btn btn-outline-secondary me-2"
                onClick={() => handleUpdate(coupon._id, newCoupon)}
              >
                Update
              </button>
              <button
                className="btn btn-outline-danger"
                onClick={() => handleDelete(coupon._id)}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CouponManagement;
