import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import productReducer from './slices/productSlice';
import cartReducer from './slices/cartSlice';
import orderReducer from './slices/orderSlice';
import categoryReducer from './slices/categorySlice';
import couponReducer from './slices/couponSlice';
import searchReducer from './slices/searchSlice';
import bannerReducer from "./slices/bannerSlice"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productReducer,
    cart: cartReducer,
    orders: orderReducer,
    categories: categoryReducer,
    coupons: couponReducer,
    search: searchReducer,
    banner: bannerReducer
  },
});
