import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Table, Button, Form } from 'react-bootstrap';
import { fetchOrders, updateOrderStatus } from '../../redux/slices/orderSlice';

const AdminOrderManagement = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const status = useSelector((state) => state.orders.status);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const handleStatusChange = (orderId, newStatus) => {
    dispatch(updateOrderStatus({ id: orderId, status: newStatus }));
  };

  console.log(orders)

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Order Management</h2>
      {status === 'loading' && <p>Loading orders...</p>}
      {status === 'failed' && <p>Failed to load orders. Please try again.</p>}
      {status === 'succeeded' && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>User</th>
              <th>Address</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>{order.user.name}</td>
                <td>{order.shippingAddress}</td>
                <td>Rs. {order.totalAmount.toFixed(2)}</td>
                <td>{order.status}</td>
                <td>
                  <Form.Control
                    as="select"
                    value={order.status}
                    onChange={(e) => handleStatusChange(order._id, e.target.value)}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Processing">Processing</option>
                    <option value="Shipped">Shipped</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Cancelled">Cancelled</option>
                  </Form.Control>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default AdminOrderManagement;
