import { useDispatch, useSelector } from 'react-redux';
import { fetchOrdersByUserId } from '../../redux/slices/orderSlice';
import { useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

const ViewOrderPage = () => {

    const dispatch = useDispatch();
    const orders = useSelector((state) => state.orders.orders);
    const status = useSelector((state) => state.orders.status);
    const isAuthenticated = useSelector((state) => state.auth.user);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchOrdersByUserId(isAuthenticated._id));
        }
    }, [dispatch, isAuthenticated]);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Failed to load orders.</div>;
    }

    return (
        <Container className="mt-5">
            <h2 className="text-center mb-4">Order Details</h2>
            <Row>
                {orders?.map((order, i) => (
                    <Col md={8} className="mx-auto" key={i}>
                        <Card className="mb-4">
                            <Card.Header className="bg-dark text-white">Order Summary</Card.Header>
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><strong>Order ID:</strong> {order?._id}</ListGroup.Item>
                                    <ListGroup.Item><strong>Status:</strong> {order?.status}</ListGroup.Item>
                                    {/* <ListGroup.Item><strong>Total Amount:</strong> Rs. {order?.totalAmount.toFixed(2)}</ListGroup.Item> */}
                                    {order?.discountedAmount && (
                                        <ListGroup.Item><strong>Discounted Amount:</strong> Rs. {order?.discountedAmount.toFixed(2)}</ListGroup.Item>
                                    )}
                                    {order?.couponApplied && (
                                        <ListGroup.Item><strong>Coupon Applied:</strong> {order?.couponApplied.code}</ListGroup.Item>
                                    )}
                                    <ListGroup.Item><strong>Shipping Address:</strong> {order?.shippingAddress}</ListGroup.Item>
                                    <ListGroup.Item><strong>Payment Method:</strong> {order?.paymentMethod}</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>

                        <Card className="mb-4">
                            <Card.Header className="bg-dark text-white">Products</Card.Header>
                            <Card.Body>
                                {order?.products?.map((item, index) => (
                                    <ListGroup key={index} variant="flush">
                                        <ListGroup.Item>
                                            <Row className="align-items-center">
                                                <Col md={8}>
                                                    <h5>{item.product.name}</h5>
                                                    <p>Quantity: {item.quantity}</p>
                                                </Col>
                                                <Col md={4} className="text-right">
                                                    <h5>Rs. {(item.product.price * item.quantity).toFixed(2)}</h5>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default ViewOrderPage;
