import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button, ListGroup, ButtonGroup, Form, Collapse, Modal } from 'react-bootstrap';
import { removeFromCart, increaseQuantity, decreaseQuantity, clearCart } from '../../redux/slices/cartSlice';
import { fetchCoupons } from '../../redux/slices/couponSlice';
import api from '../../api/api';
import logo_alsasa from "../../assets/logo192.png"
const CheckoutPage = () => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const coupons = useSelector((state) => state.coupons.coupons);
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [address, setAddress] = useState({ line1: '', line2: '', city: '', state: '', zip: '', country: '' });
  const [mobileNumber, setMobileNumber] = useState("")
  const [validated, setValidated] = useState(false);

  // Fetch coupons on mount
  React.useEffect(() => {
    dispatch(fetchCoupons());
  }, [dispatch]);

  const handleIncreaseQuantity = (item) => {
    dispatch(increaseQuantity(item));
  };

  const handleDecreaseQuantity = (item) => {
    dispatch(decreaseQuantity(item));
  };

  const handleRemoveItem = (item) => {
    dispatch(removeFromCart(item));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const isAuthenticated = useSelector((state) => state.auth.user);

  const subtotal = cartItems.reduce((total, item) =>
    total + ((item.discountPrice && item.discountPrice > 0)
      ? item.discountPrice * item.quantity
      : item.price * item.quantity), 0) - discountAmount;

  const totalAmount = subtotal < 2000 ? subtotal + 200 : subtotal + 0;

  const handleApplyCoupon = (e) => {
    e.preventDefault();
    const selectedCoupon = coupons.find((coupon) => coupon.code === couponCode);
    if (selectedCoupon) {
      setAppliedCoupon(selectedCoupon);
      setDiscountAmount((totalAmount * selectedCoupon.discount) / 100);
    } else {
      alert('Invalid coupon code');
    }
  };

  const handleRemoveCoupon = (e) => {
    e.preventDefault();
    setAppliedCoupon(null);
    setDiscountAmount(0);
    setCouponCode('');
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const orderPlace = () => {
    console.log('Order placed');
  };

  const handlePayment = async () => {
    // Show address modal before proceeding to payment
    setShowAddressModal(true);
  };

  const handleAddressSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    setShowAddressModal(false);

    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    let paymentMethod = 'Razorpay';

    try {
      const response = await api.post('/orders', {
        cartItems,
        totalAmount,
        shippingAddress: address,
        mobileNumber,
        paymentMethod,
        coupon: appliedCoupon?._id, // Optional: add applied coupon
      });

      const { order, key_id, amount } = response.data;

      const options = {
        key: key_id,
        amount: amount * 100,
        currency: 'INR',
        name: 'Alsasa',
        description: 'Order Payment',
        image: { logo_alsasa },
        order_id: order.id,
        handler: async (response) => {
          const paymentResult = await api.post('/orders/verify-payment', {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            user: isAuthenticated,
            cartItems,
            couponApplied: appliedCoupon,
            shippingAddress: `${address?.line1} ${address?.line2} ${address?.city} ${address?.state} ${address?.zip} ${address?.country}`,
            mobileNumber,
            paymentMethod,
            totalAmount,
          });

          if (paymentResult.data.success) {
            alert('Payment successful and order placed!');
            orderPlace();
          } else {
            alert('Payment verification failed');
          }
        },
        prefill: {
          name: isAuthenticated.name,
          email: isAuthenticated.email,
        },
        notes: {
          address: 'India',
        },
        theme: {
          color: '#CFB53B',
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };

  const handleApplyCouponFromList = (selectedCoupon) => {
    if (selectedCoupon) {
      setAppliedCoupon(selectedCoupon);
      setDiscountAmount((totalAmount * selectedCoupon.discount) / 100);
      setCouponCode(selectedCoupon.code); // Update the input field as well
    } else {
      alert('Invalid coupon selected');
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Checkout</h2>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            {cartItems.map((item) => (
              <ListGroup.Item key={item._id + item.selectedSize} className="d-flex justify-content-between align-items-start">
                <div>
                  <h5>{item.name}</h5>
                  <p className="mb-2">Size: {item.selectedSize}</p>
                  <div className="d-flex align-items-center">
                    <p className="mb-0">Quantity: </p>
                    <ButtonGroup size="sm" className="ml-2">
                      <Button variant="outline-dark" onClick={() => handleDecreaseQuantity(item)}>
                        -
                      </Button>
                      <Button variant="outline-dark" disabled>
                        {item.quantity}
                      </Button>
                      <Button variant="outline-dark" onClick={() => handleIncreaseQuantity(item)}>
                        +
                      </Button>
                    </ButtonGroup>
                  </div>
                  {appliedCoupon && (
                    <div className="py-md-5 py-3">
                      <h5>Coupon Applied</h5>
                      <div className="d-flex justify-content-between align-items-start">
                        <div>
                          {appliedCoupon.code} {appliedCoupon.discount}%
                        </div>
                        <Button variant="outline-danger" size="sm" onClick={handleRemoveCoupon}>
                          Remove
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <h5>Rs. {(item.discountPrice && item.discountPrice > 0 ? item.discountPrice * item.quantity : item.price).toFixed(2)}</h5>
                  <Button variant="danger" size="sm" onClick={() => handleRemoveItem(item)}>
                    Remove
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col md={4}>
          <div className="p-3 bg-light rounded">
            <h4>Total: Rs. {totalAmount.toFixed(2)}</h4>
            {totalAmount < 2000 ? <span>Rs. {subtotal.toFixed(2)} + Rs. 200 (For Shipping)</span> : <span>Free Shipping over Rs. 1999</span>}
            <Form>
              <Form.Group controlId="formCouponCode">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control
                  type="text"
                  className="my-2"
                  placeholder="Enter coupon code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  disabled={!!appliedCoupon}
                />
              </Form.Group>
              <Button
                variant="outline-dark"
                className="w-100 mb-2"
                onClick={handleApplyCoupon}
                disabled={!!appliedCoupon}
              >
                Apply Coupon
              </Button>
            </Form>
            <div className="py-2">
              {/* <Button
                onClick={() => setOpen(!open)}
                aria-controls="collapse-text"
                aria-expanded={open}
                className="w-100 mb-2"
                variant="outline-dark"
              >
                {open ? 'Hide' : 'Show'} Coupons
              </Button> */}
              {/* <Collapse in={open}>
                <div id="collapse-text">
                  {coupons.map((c, i) => (
                    <div key={i} className="d-flex justify-content-between align-items-start py-2">
                      <div>
                        {c.code} - {c.discount}%
                      </div>
                      <Button
                        variant="outline-success"
                        size="sm"
                        onClick={() => handleApplyCouponFromList(c)}
                        disabled={!!appliedCoupon}
                      >
                        Apply
                      </Button>
                    </div>
                  ))}
                </div>
              </Collapse> */}
            </div>

            <Button variant="outline-dark" className="w-100 mb-2" onClick={handlePayment} disabled={cartItems.length === 0}>
              Proceed to Payment
            </Button>
            <Button variant="dark" className="w-100 mb-2" onClick={handleClearCart}>
              Clear Cart
            </Button>
          </div>
        </Col>
      </Row>

      {/* Address Modal */}
      <Modal show={showAddressModal} onHide={() => setShowAddressModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Shipping Address</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={handleAddressSubmit}>
          <Modal.Body>
            <Form.Group controlId="mobileNumber">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="addressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="1234 Main St"
                value={address.line1}
                onChange={(e) => setAddress({ ...address, line1: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid address.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="addressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apartment, studio, or floor"
                value={address.line2}
                onChange={(e) => setAddress({ ...address, line2: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="City"
                value={address.city}
                onChange={(e) => setAddress({ ...address, city: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid city.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="state">
              <Form.Label>State</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="State"
                value={address.state}
                onChange={(e) => setAddress({ ...address, state: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid state.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="zip">
              <Form.Label>Zip</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Zip Code"
                value={address.zip}
                onChange={(e) => setAddress({ ...address, zip: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid zip code.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="country">
              <Form.Label>Country</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Country"
                value={address.country}
                onChange={(e) => setAddress({ ...address, country: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid country.</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={() => setShowAddressModal(false)}>
              Close
            </Button>
            <Button variant="outline-dark" type="submit">
              Proceed to Payment
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default CheckoutPage;
