import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../api/api';

const OrderDetail = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await api.get(`/orders/${id}`);
        setOrder(response.data);
      } catch (error) {
        console.error('Failed to fetch order', error);
      }
    };
    fetchOrder();
  }, [id]);

  if (!order) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Order #{order._id}</h2>
      <p>Status: {order.status}</p>
      <p>Total: ${order.total}</p>
      <ul>
        {order.items.map((item) => (
          <li key={item._id}>
            <h3>{item.name}</h3>
            <p>${item.price} x {item.quantity}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderDetail;
