import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../redux/slices/categorySlice';

const CategoryList = () => {
  const dispatch = useDispatch();
  const { categories, status } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  return (
    <div>
      <h2>Categories</h2>
      {status === 'loading' ? <p>Loading...</p> : (
        <ul>
          {categories.map((category) => (
            <li key={category._id}>
              <h3>{category.name}</h3>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CategoryList;
