import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanners, createBanner, deleteBanner } from '../../redux/slices/bannerSlice';

const BannerManagement = () => {
  const dispatch = useDispatch();
  const {banners} = useSelector((state) => state.banner);
  const [newBanner, setNewBanner] = useState({ title: '', imageUrl: '' });

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  const handleCreateBanner = () => {
    if (newBanner.title && newBanner.imageUrl) {
      dispatch(createBanner(newBanner));
      setNewBanner({ title: '', imageUrl: '' });
    }
  };

  const handleDeleteBanner = (id) => {
    dispatch(deleteBanner(id));
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Manage Banners</h2>
      <div className="card p-4 mb-4">
        <div className="form-group mb-3">
          <label htmlFor="bannerTitle">Banner Title</label>
          <input
            type="text"
            id="bannerTitle"
            className="form-control"
            placeholder="Enter banner title"
            value={newBanner.title}
            onChange={(e) => setNewBanner({ ...newBanner, title: e.target.value })}
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="bannerImageUrl">Banner Image URL</label>
          <input
            type="text"
            id="bannerImageUrl"
            className="form-control"
            placeholder="Enter image URL"
            value={newBanner.imageUrl}
            onChange={(e) => setNewBanner({ ...newBanner, imageUrl: e.target.value })}
          />
        </div>
        <button className="btn btn-primary" onClick={handleCreateBanner}>
          Add Banner
        </button>
      </div>

      <ul className="list-group">
        {banners.map((banner) => (
          <li key={banner._id} className="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <h5>{banner?.title}</h5>
              <img src={banner?.imageUrl} alt={banner?.title} className="img-thumbnail" style={{ width: '100px' }} />
            </div>
            <button
              className="btn btn-outline-danger"
              onClick={() => handleDeleteBanner(banner?._id)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BannerManagement;
