import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

// Async thunk to fetch search results by query
export const fetchSearchResults = createAsyncThunk(
  'search/fetchSearchResults',
  async (query, { rejectWithValue }) => {
    try {
      const response = await api.get(`/products/search?q=${query}`);
      return response.data.reverse();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch search results by category
export const fetchCategoryResults = createAsyncThunk(
  'search/fetchCategoryResults',
  async (categoryId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/categories/${categoryId}/products`);
      return response.data.reverse();
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchResults: [],
    categoryResults: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle search by query
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.loading = false;
        state.searchResults = action.payload;
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle search by category
    builder
      .addCase(fetchCategoryResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategoryResults.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryResults = action.payload;
      })
      .addCase(fetchCategoryResults.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default searchSlice.reducer;
