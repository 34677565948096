import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Carousel, Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../redux/slices/cartSlice';
import api from '../../api/api';
import { fetchCategoryResults } from '../../redux/slices/searchSlice';
import ProductCard from '../utils/ProductCard';
import { Helmet } from "react-helmet";

const SingleProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { categoryResults } = useSelector((state) => state.search);
  const isAuthenticated = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await api.get(`/products/${id}`);
        setProduct(response.data);
        if (response.data.sizesAvailable.length > 0) {
          setSelectedSize(response.data.sizesAvailable[0]);
        }
        dispatch(fetchCategoryResults(response.data.category));
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [id, dispatch]);

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleAddToCart = () => {
    if (selectedSize) {
      dispatch(addToCart({ ...product, quantity: 1, selectedSize }));
    }
  };

  const handleBuyNow = () => {
    if (selectedSize) {
      dispatch(addToCart({ ...product, quantity: 1, selectedSize }));
      navigate('/cart');
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{product.name} - Alsasa Hijabs</title>
        <meta name="description" content={`Shop the ${product.name} at Alsasa Hijabs. ${product.description}`} />
        <meta name="keywords" content={`hijabs, ${product.name}, ${product.description}`} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://www.alsasa.in/products/${product.name}`} />
        <meta property="og:title" content={`${product.name} - Alsasa Hijabs`} />
        <meta property="og:description" content={`Get the stylish ${product.name} in ${product.description}`} />
        <meta property="og:url" content={`https://www.alsasa.in/products/${product._id}`} />
        <meta property="og:type" content="product" />
        <meta property="og:image" content={product.imageUrls[0]} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${product.name} - Alsasa Hijabs`} />
        <meta name="twitter:description" content={`Check out the ${product.name}. Available in ${product.sizesAvailable[0]}. Order now!`} />
        <meta name="twitter:image" content={product.imageUrls[0]} />
      </Helmet>
      <Container className="mt-5">
        <Row className='py-md-3 py-5'>
          <Col lg={6}>
            <Carousel activeIndex={selectedImageIndex} onSelect={handleThumbnailClick} indicators={false}>
              {product.imageUrls.map((url, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={url}
                    style={{
                      height: "100%"
                    }}
                    alt={`Product ${index + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="d-flex justify-content-center mt-3">
              {product.imageUrls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => handleThumbnailClick(index)}
                  style={{
                    width: '60px',
                    height: '60px',
                    objectFit: 'cover',
                    marginRight: '5px',
                    cursor: 'pointer',
                    border: index === selectedImageIndex ? '2px solid #CFB53B' : '1px solid #ddd',
                  }}
                />
              ))}
            </div>
          </Col>
          <Col lg={6} className="d-flex flex-column justify-content-start">
            <h1 className="mb-3">{product.name}</h1>
            <p className="text-muted mb-4">{product.description}</p>
            {parseInt(product?.discountPrice) === 0 ?
              <>
                <h3 className="mb-4">Rs. {product?.price?.toFixed(2)}</h3>
              </> :
              <>
                <h3 className="mb-4">Rs. {product?.discountPrice?.toFixed(2)}</h3>
                <h5 className="mb-4"><strike>Rs. {product.price.toFixed(2)}</strike></h5>
              </>}
            <FormGroup className="mb-3">
              <FormLabel>Select Size</FormLabel>
              <FormControl
                as="select"
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
              >
                {product.sizesAvailable.map((size, index) => (
                  <option key={index} value={size}>
                    {size}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
            <Button variant="dark" className="w-100 mb-2" onClick={handleAddToCart}>
              Add to Cart
            </Button>
            {isAuthenticated ?
              <Button variant="outline-dark" className="w-100" onClick={handleBuyNow}>
                Buy Now
              </Button>
              :
              <Button variant="outline-dark" className="w-100" onClick={() => navigate('/login')}>
                Login to buy now
              </Button>
            }
          </Col>
        </Row>
        <div>
          <div className='py-5 py-md-5'>
            <div className='text-center'>
              <span className='fs-2'>Related Products</span>
            </div>
          </div>
          <div>
            <Row>
              {categoryResults.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SingleProductPage;
