import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../redux/slices/productSlice';
import ProductCard from '../utils/ProductCard';
import { Row } from 'react-bootstrap';

const ProductList = () => {
  const dispatch = useDispatch();
  const { products, status } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  return (
    <div className="container-fluid mt-4">
      <h2 className="mb-4">Products</h2>
      {status === 'loading' ? (
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className='p-5'>
          {products.length > 0 ? (
            <Row>
              {products.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </Row>
          ) : (
            <p className="text-center">No products available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductList;
