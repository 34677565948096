import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { fetchSearchResults, fetchCategoryResults } from '../../redux/slices/searchSlice';
import { Button, Form } from 'react-bootstrap';
import { fetchCategories } from '../../redux/slices/categorySlice';
import './product.css'
import ProductCard from '../utils/ProductCard';
import { Helmet } from 'react-helmet';

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const location = useLocation();
  const dispatch = useDispatch();
  const { searchResults, categoryResults, loading, error } = useSelector((state) => state.search);
  const { categories } = useSelector((state) => state.categories);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('q');
    const categoryParam = params.get('categoryId');

    setQuery(searchQuery || '');
    setCategoryId(categoryParam || '');

    if (searchQuery) {
      dispatch(fetchSearchResults(searchQuery));
    } else if (categoryParam) {
      dispatch(fetchCategoryResults(categoryParam));
    }

    // Fetch categories if they haven't been loaded yet
    if (categories?.length === 0) {
      dispatch(fetchCategories());
    }
  }, [dispatch, location.search, categories?.length]);

  const handleSearch = (e) => {
    e.preventDefault()
    if (query) {
      dispatch(fetchSearchResults(query));
    } else if (categoryId) {
      dispatch(fetchCategoryResults(categoryId));
    }
  };

  return (
    <>
      <Helmet>
        <title>Search Results for "{query}" - Alsasa Hijabs</title>
        <meta name="description" content={`Find hijabs that match your search for "${query}" at Alsasa Hijabs. Explore our wide selection of styles, colors, and fabrics.`} />
        <meta name="keywords" content={`hijabs, search hijabs, ${query}, hijab fashion, scarves, Islamic clothing`} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://www.alsasa.in/search?q=${encodeURIComponent(query)}`} />
        <meta property="og:title" content={`Search Results for "${query}" - Alsasa Hijabs`} />
        <meta property="og:description" content={`Explore hijabs that match your search query "${query}". Browse through various styles and colors at Alsasa Hijabs.`} />
        <meta property="og:url" content={`https://www.alsasa.in/search?q=${encodeURIComponent(query)}`} />
        <meta property="og:type" content="website" />
        {/* <meta property="og:image" content="https://www.alsasa.in/assets/images/search-results.jpg" /> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Search Results for "${query}" - Alsasa Hijabs`} />
        <meta name="twitter:description" content={`Find the perfect hijab for "${query}" at Alsasa Hijabs. Shop our collection today!`} />
        {/* <meta name="twitter:image" content="https://www.alsasa.in/assets/images/search-results.jpg" /> */}
      </Helmet>
      <Container>
        <h2 className="my-4">Search Results</h2>
        <Form className="mb-4 d-flex" onSubmit={handleSearch}>
          <Form.Control
            type="text"
            placeholder="Search by query..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Form.Select
            aria-label="Select Category"
            onChange={(e) => {
              setCategoryId(e.target.value)
              setQuery('')
            }}
            value={categoryId}
            className="mx-2"
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
          <Button type="submit">Search</Button>
        </Form>
        {loading ? (
          <Spinner animation="border" />
        ) : error ? (
          <p className="text-danger">Error loading products: {error}</p>
        ) : (
          <Row>
            {query && searchResults.length ? (
              <Row>
                {searchResults.map((product) => (
                  <ProductCard key={product._id} product={product} />
                ))}
              </Row>
            ) : categoryResults.length ? (
              <Row>
                {categoryResults.map((product) => (
                  <ProductCard key={product._id} product={product} />
                ))}
              </Row>
            ) : (
              <p>No products found.</p>
            )}
          </Row>
        )}
      </Container>
    </>
  );
};

export default SearchPage;
