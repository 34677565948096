import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

const getToken = () => localStorage.getItem('token');

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  const token = getToken();
  const response = await api.get('/categories',{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data.reverse();
});

export const createCategory = createAsyncThunk('categories/createCategory', async (category) => {
  const token = getToken();
  const response = await api.post('/categories', category, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const updateCategory = createAsyncThunk('categories/updateCategory', async ({ id, ...category }) => {
  const token = getToken();
  const response = await api.put(`/categories/${id}`, category, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
});

export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (id) => {
  const token = getToken();
  await api.delete(`/categories/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return id;
});

const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    status: 'idle',
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.categories.push(action.payload);
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        const index = state.categories.findIndex(category => category._id === action.payload._id);
        state.categories[index] = action.payload;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.categories = state.categories.filter(category => category._id !== action.payload);
      });
  },
});

export default categorySlice.reducer;
