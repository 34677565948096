import React, { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { addToCart } from '../../redux/slices/cartSlice';
import { useDispatch, useSelector } from 'react-redux';

const ProductCard = ({ product }) => {
    const [hovered, setHovered] = useState(false);
    const dispatch = useDispatch();

    const navigate = useNavigate()

    const isAuthenticated = useSelector((state) => state.auth.user);

    const handleAddToCart = (e) => {

        if (!isAuthenticated) {
            return navigate('/login')
        } else {
            e.preventDefault(); // Prevent the link's default behavior
            e.stopPropagation(); // Prevent the event from bubbling up to the Link
            dispatch(addToCart({ ...product, quantity: 1, selectedSize: product.sizesAvailable[0] }));
        }

    };

    return (
        <Col
            lg={3}
            sm={4}
            xs={6}
            key={product._id}
            className="mb-4"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Card as={Link} to={`/products/${product._id}`} className="rounded-0 border-0 shadow-none position-relative">
                <Card.Img
                    variant="top"
                    src={hovered ? product.imageUrls[1] : product.imageUrls[0]}
                    className=""
                />
                {hovered && (
                    <div className="product-overlay d-flex justify-content-evenly align-items-center">
                        <button onClick={handleAddToCart} className="login-button rounded-1 fw-bold">
                            Add to Cart
                        </button>
                    </div>
                )}
                <Card.Body className="px-0">
                    <Card.Title className="">{product.name}</Card.Title>
                    {parseInt(product?.discountPrice) === 0 ? <Card.Text className=""><span className='fs-5'>Rs. {product?.price?.toFixed(2)}</span></Card.Text> : <Card.Text className=""><span className='fs-5'>Rs. {product?.discountPrice?.toFixed(2)}</span> <span className='my-2'> <strike>Rs. {product.price.toFixed(2)}</strike></span></Card.Text>}
                </Card.Body>
            </Card>
        </Col>
    );
};

export default ProductCard;
