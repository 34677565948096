import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts, deleteProduct, createProduct, updateProduct } from '../../redux/slices/productSlice';
import { fetchCategories } from '../../redux/slices/categorySlice';
import { Button, Form, FormControl, FormGroup, FormLabel, ListGroup, Modal } from 'react-bootstrap';

const predefinedSizes = ['Small', 'Medium', 'Large', 'X-Large', 'XX-Large'];

const ProductManagement = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.categories);

  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    price: 0,
    discountPrice: 0,
    category: '',
    productType: 'Normal',
    stock: 0,
    imageUrls: [''],
    sizesAvailable: [], // Initialize as an empty array
  });

  const [editProduct, setEditProduct] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
  };

  const handleCreate = () => {
    dispatch(createProduct(newProduct));
    setNewProduct({
      name: '',
      description: '',
      price: 0,
      discountPrice: 0,
      category: '',
      productType: 'Normal',
      stock: 0,
      imageUrls: [''],
      sizesAvailable: [],
    });
  };

  const handleUpdate = () => {
    dispatch(updateProduct({ id: editProduct._id, ...editProduct }));
    setShowEditModal(false);
    setEditProduct(null);
  };

  const handleAddImageUrl = () => {
    setNewProduct({
      ...newProduct,
      imageUrls: [...newProduct.imageUrls, ''],
    });
  };

  const handleAddSize = (size) => {
    if (!newProduct.sizesAvailable.includes(size)) {
      setNewProduct({
        ...newProduct,
        sizesAvailable: [...newProduct.sizesAvailable, size],
      });
    }
  };

  const handleRemoveSize = (size) => {
    setNewProduct({
      ...newProduct,
      sizesAvailable: newProduct.sizesAvailable.filter((s) => s !== size),
    });
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Manage Products</h2>

      {/* Add New Product Form */}
      <div className="mb-4">
        <h3>Add New Product</h3>
        <Form>
          <FormGroup className="mb-3">
            <FormLabel>Name</FormLabel>
            <FormControl
              type="text"
              placeholder="Product Name"
              value={newProduct.name}
              onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Description</FormLabel>
            <FormControl
              as="textarea"
              rows={3}
              placeholder="Product Description"
              value={newProduct.description}
              onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Price</FormLabel>
            <FormControl
              type="number"
              placeholder="Price"
              value={newProduct.price}
              onChange={(e) => setNewProduct({ ...newProduct, price: Number(e.target.value) })}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Discount Price</FormLabel>
            <FormControl
              type="number"
              placeholder="Discount Price"
              value={newProduct.discountPrice}
              onChange={(e) => setNewProduct({ ...newProduct, discountPrice: Number(e.target.value) })}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Category</FormLabel>
            <FormControl
              as="select"
              value={newProduct.category}
              onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
            >
              <option value="">Select Category</option>
              {categories.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Product Type</FormLabel>
            <FormControl
              as="select"
              value={newProduct.productType}
              onChange={(e) => setNewProduct({ ...newProduct, productType: e.target.value })}
            >
              <option value="Feature">Feature</option>
              <option value="New Arrival">New Arrival</option>
              <option value="Normal">Normal</option>
            </FormControl>
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Stock</FormLabel>
            <FormControl
              type="number"
              placeholder="Stock"
              value={newProduct.stock}
              onChange={(e) => setNewProduct({ ...newProduct, stock: Number(e.target.value) })}
            />
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Image URLs</FormLabel>
            {newProduct.imageUrls.map((url, index) => (
              <FormControl
                key={index}
                type="text"
                placeholder="Image URL"
                value={url}
                onChange={(e) => {
                  const updatedUrls = [...newProduct.imageUrls];
                  updatedUrls[index] = e.target.value;
                  setNewProduct({ ...newProduct, imageUrls: updatedUrls });
                }}
                className="mb-2"
              />
            ))}
            <Button variant="secondary" onClick={handleAddImageUrl}>
              Add Another Image
            </Button>
          </FormGroup>

          <FormGroup className="mb-3">
            <FormLabel>Sizes Available</FormLabel>
            <div className="d-flex flex-wrap">
              {newProduct.sizesAvailable.map((size, index) => (
                <Button
                  key={index}
                  variant="outline-danger"
                  className="m-1"
                  onClick={() => handleRemoveSize(size)}
                >
                  {size} <span>&times;</span>
                </Button>
              ))}
            </div>
            <FormControl
              as="select"
              value=""
              onChange={(e) => handleAddSize(e.target.value)}
              className="mt-2"
            >
              <option value="" disabled>Select Size</option>
              {predefinedSizes.map((size, index) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </FormControl>
          </FormGroup>

          <Button variant="primary" onClick={handleCreate}>
            Create Product
          </Button>
        </Form>
      </div>

      {/* Product List */}
      <ListGroup>
        {products.map((product) => (
          <ListGroup.Item key={product._id} className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>{product.name}</h5>
                <p>{product.description}</p>
                <p>Price: Rs. {product.price}</p>
                <p>Discount Price: Rs. {product.discountPrice}</p>
                <p>Stock: {product.stock}</p>
                <p>Category: {categories.find((cat) => cat._id === product.category)?.name}</p>
                <p>Product Type: {product.productType}</p>
                {product.imageUrls.map((url, index) => (
                  <img key={index} src={url} alt={product.name} style={{ width: '100px', marginRight: '10px' }} />
                ))}
                <p>Sizes Available: {product.sizesAvailable.join(', ')}</p>
              </div>
              <div>
                <Button
                  variant="warning"
                  className="me-2"
                  onClick={() => {
                    setEditProduct(product);
                    setShowEditModal(true);
                  }}
                >
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(product._id)}>
                  Delete
                </Button>
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {/* Edit Product Modal */}
      {editProduct && (
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup className="mb-3">
                <FormLabel>Name</FormLabel>
                <FormControl
                  type="text"
                  value={editProduct.name}
                  onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>Description</FormLabel>
                <FormControl
                  as="textarea"
                  rows={3}
                  value={editProduct.description}
                  onChange={(e) => setEditProduct({ ...editProduct, description: e.target.value })}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>Price</FormLabel>
                <FormControl
                  type="number"
                  value={editProduct.price}
                  onChange={(e) => setEditProduct({ ...editProduct, price: Number(e.target.value) })}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>Discount Price</FormLabel>
                <FormControl
                  type="number"
                  placeholder="Discount Price"
                  value={editProduct.discountPrice}
                  onChange={(e) => setEditProduct({ ...editProduct, discountPrice: Number(e.target.value) })}
                />
              </FormGroup>
              
              <FormGroup className="mb-3">
                <FormLabel>Category</FormLabel>
                <FormControl
                  as="select"
                  value={editProduct.category}
                  onChange={(e) => setEditProduct({ ...editProduct, category: e.target.value })}
                >
                  {categories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>Product Type</FormLabel>
                <FormControl
                  as="select"
                  value={editProduct.productType}
                  onChange={(e) => setEditProduct({ ...editProduct, productType: e.target.value })}
                >
                  <option value="Feature">Feature</option>
                  <option value="New Arrival">New Arrival</option>
                  <option value="Normal">Normal</option>
                </FormControl>
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>Stock</FormLabel>
                <FormControl
                  type="number"
                  value={editProduct.stock}
                  onChange={(e) => setEditProduct({ ...editProduct, stock: Number(e.target.value) })}
                />
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>Image URLs</FormLabel>
                {editProduct.imageUrls.map((url, index) => (
                  <FormControl
                    key={index}
                    type="text"
                    value={url}
                    onChange={(e) => {
                      const updatedUrls = [...editProduct.imageUrls];
                      updatedUrls[index] = e.target.value;
                      setEditProduct({ ...editProduct, imageUrls: updatedUrls });
                    }}
                    className="mb-2"
                  />
                ))}
                <Button
                  variant="secondary"
                  onClick={() =>
                    setEditProduct({ ...editProduct, imageUrls: [...editProduct.imageUrls, ''] })
                  }
                >
                  Add Another Image
                </Button>
              </FormGroup>

              <FormGroup className="mb-3">
                <FormLabel>Sizes Available</FormLabel>
                <div className="d-flex flex-wrap">
                  {editProduct.sizesAvailable.map((size, index) => (
                    <Button
                      key={index}
                      variant="outline-danger"
                      className="m-1"
                      onClick={() => {
                        const updatedSizes = editProduct.sizesAvailable.filter((s) => s !== size);
                        setEditProduct({ ...editProduct, sizesAvailable: updatedSizes });
                      }}
                    >
                      {size} <span>&times;</span>
                    </Button>
                  ))}
                </div>
                <FormControl
                  as="select"
                  value=""
                  onChange={(e) => {
                    if (!editProduct.sizesAvailable.includes(e.target.value)) {
                      setEditProduct({
                        ...editProduct,
                        sizesAvailable: [...editProduct.sizesAvailable, e.target.value],
                      });
                    }
                  }}
                  className="mt-2"
                >
                  <option value="" disabled>Select Size</option>
                  {predefinedSizes.map((size, index) => (
                    <option key={index} value={size}>
                      {size}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdate}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ProductManagement;
